import React from 'react';
import Head from 'next/head';
import Page from '../contentful/content-types/Page';
import NotFound from '../components/organs/NotFound';
import Navbar from '../contentful/content-types/Navbar';
import Footer from '../contentful/content-types/Footer';
import createSlug from '../utils/createSlug';
import StaticPage from '../components/organs/StaticPage';
import ObjectPage from '../components/organs/ObjectPage';
import HouseInfo from '../components/organs/ObjectPage/HouseInfo';
import { wrapper, AppState } from '../redux/store';
import {connect, useSelector} from 'react-redux';
import { useRouter } from 'next/router';
import {
  fetchBiddingInfo,
  fetchObjects,
  fetchAssets,
  fetchPageBySlug, fetchEntries,
} from '../redux/slices/mainSlice';
import axios from 'axios';
import {log} from "util";

function resolveSource(referer, baseId, url) {
  const qs = url.split("?").at(-1);
  const qsParams = new URLSearchParams(qs)
  const utm_source = qsParams.get("utm_source");
  const fbclid = qsParams.get("fbclid");

  if (utm_source) {
    return utm_source;
  }

  if (fbclid) {
    return "l.facebook.com";
  }

  const parsedUrl = new URL(referer);
  const domain = parsedUrl.hostname;
  const supportedReferers = [
    "www.hemnet.se",
    "www.booli.se",
    "bovision.se",
    "www.facebook.com",
    "l.facebook.com",
  ];
  if (supportedReferers.includes(domain)) {
    return domain;
  }

  return "www.privatmaklaren.se";
}

function reportPageView(referer, baseId, url) {
  axios.post(`${process.env.NEXT_PUBLIC_API_ENDPOINT}v2/object/pageView`, {
    "nBaseId": baseId,
    "sSource": resolveSource(referer, baseId, url)
  })
    .catch(e => {
      console.log("The pageview request failed");
    })
}

const AllPages = (props) => {
  const { pageType, data, slug } = props;
  const { currentPage, baseId, houseType, objectSlug, isLp } = data;

  const router = useRouter();

  if (pageType === 'STATIC_PAGE') {
    const title =
      slug === 'till-salu'
        ? 'Bostäder till salu'
        : currentPage
        ? currentPage.fields.metaTitle
        : '';
    const description =
      slug === 'till-salu'
        ? 'Hus, lägenhet, fritidshus och tomter säljes privat.'
        : currentPage
        ? currentPage.fields.metaDescription
        : '';
    return (
      <StaticPage
        slug={slug}
        title={title}
        description={description}
        data={currentPage}
      />
    );
  }
  if (pageType === 'NOT_FOUND') {
    return <NotFound />;
  }
  if (pageType === 'OBJECT_PAGE')
    return (
      <>
        <ObjectPage baseId={baseId} houseType={houseType} slug={objectSlug} />
      </>
    );
  if (pageType === 'OBJECT_PAGE_API')
    return (
      <>
        <Navbar />
        <HouseInfo data={data} />
        <Footer />
      </>
    );

  return (
    <>
      <>
        {currentPage === null ? (
          <NotFound />
        ) : (
          <>
            <Head>
              <title>{currentPage.fields.metaTitle}</title>
              <meta
                name="description"
                content={currentPage.fields.metaDescription}
              />
            </Head>
            <Page data={currentPage} isLp={isLp} />
          </>
        )}
      </>
    </>
  );
};

function createUrlFromContext(context) {
  const slugs = typeof context.query.slug === "string" ? [context.query.slug] : context.query.slug;
  let queryComponents = context.query;
  // Delete the page query component added by Next.js
  delete queryComponents.slug;
  queryComponents = Object.entries(queryComponents);
  let url = "https://www.privatmaklaren.se/"+slugs.join("/")+"?";
  queryComponents.forEach(c => {
    url = `${url}${c[0]}=${c[1]}&`;
  })
  return url;
}

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async (context) => {
    const referer = context.req.headers.referer;

    const slug = ((context.query.slug || []) as string[]).join('/');
    const slugs = (context.query.slug || []) as string[];

    if (store.getState().main.entries.length === 0) {
      await Promise.all([
        store.dispatch(fetchEntries()),
        store.dispatch(fetchPageBySlug(context.query.slug ? slug : '')),
        store.dispatch(fetchAssets()),
        store.dispatch(fetchObjects()),
        store.dispatch(fetchBiddingInfo()),
      ]);
    }

    const searchRes = store
      .getState()
      .main.objects.filter((i) => i.BaseID == slug);
    if (searchRes.length > 0) {
      return {
        redirect: {
          permanent: false,
          destination: searchRes[0].Slug,
        },
      };
    }
    const res = store.getState().main.entries;
    const houseTypes = store.getState().main.houseTypes;
    const objectEntries = res.filter(
      (item) => item.sys.contentType.sys.id === 'page'
    );
    const index = objectEntries
      .map((item) => (item.fields as any).slug ?? '')
      .indexOf(slug);
    const currentPage = index >= 0 ? objectEntries[index] : null;
    let pageType = 'NOT_FOUND';
    if (currentPage !== null) pageType = 'CONTENTFUL_PAGE';
    pageType =
      slug === 'salja' || slug === 'till-salu'
        ? 'STATIC_PAGE'
        : pageType;
    houseTypes.forEach((element) => {
      if (slugs[0] === createSlug(element.Type)) pageType = 'OBJECT_PAGE';
    });
    if (slugs.length !== 2 && pageType === 'OBJECT_PAGE')
      pageType = 'NOT_FOUND';
    let baseId = '';
    if (pageType === 'OBJECT_PAGE') {
      const url = createUrlFromContext(context);
      baseId = url.split('?').at(0).split('-').at(-1);
      // Report statistics for this baseId
      if (baseId) {
        try {
          reportPageView(referer, baseId, url);
        } catch(e) {
          // The available referer/query string could not be parsed successfully
        }
      }
    }
    const objectExist = !!!store
      .getState()
      .main.objects.map((i) => i.BaseID)
      .filter((i) => i === baseId).length;
    if (
      pageType === 'NOT_FOUND' ||
      (pageType === 'OBJECT_PAGE' && objectExist)
    ) {
      let objectFromAPI;
      await axios
        .get(
          `${
            process.env.NEXT_PUBLIC_API_ENDPOINT
          }v2/object/${encodeURIComponent(slug)}`
        )
        .then((res) => (objectFromAPI = res))
        .catch((err) => (objectFromAPI = undefined));
      if (objectFromAPI !== undefined)
        return {
          props: {
            pageType: 'OBJECT_PAGE_API',
            data: objectFromAPI.data,
          },
        };
    }
    let data = {};
    switch (pageType) {
      case 'STATIC_PAGE': {
        data = {
          currentPage,
        };
        break;
      }
      case 'CONTENTFUL_PAGE': {
        data = {
          currentPage,
          isLp: false,
        };
        break;
      }
      case 'OBJECT_PAGE': {
        data = {
          baseId,
          houseType: slugs[0],
          objectSlug: slugs[1],
        };
        break;
      }
    }
    return {
      props: {
        pageType,
        slug,
        data,
      },
    };
  }
);

const mapStateToProps = (state: AppState) => ({
  entries: state.main.entries,
  assets: state.main.assets,
  objects: state.main.objects,
  houseTypes: state.main.houseTypes,
});

export default connect(mapStateToProps)(AllPages);
