import React from 'react';
import BuyListXS from './BuyListXS';
import BuyListLG from './BuyListLG';

import useWidth from '../../../utils/useWidth';
import Link from 'next/link';
import { connect } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import createSlug from '../../../utils/createSlug';

const ResponsiveComponent = ({
  data,
  soldObjects,
  houseTypes,
  associationTypes,
  municipalities,
  biddingInfo,
}) => {
  const width = useWidth();
  return (
    <>
      {width === 'lg' || width === 'xl' || width === 'md' || width === 'sm' ? (
        <BuyListLG
          data={data}
          soldObjects={soldObjects}
          houseTypes={houseTypes}
          municipalities={municipalities}
          associationTypes={associationTypes}
          biddingInfo={biddingInfo}
        />
      ) : (
        <BuyListXS
          data={data}
          soldObjects={soldObjects}
          houseTypes={houseTypes}
          municipalities={municipalities}
          associationTypes={associationTypes}
          biddingInfo={biddingInfo}
        />
      )}
    </>
  );
};
const BuyList = ({
  objects,
  soldObjects,
  associationTypes,
  municipalities,
  houseTypes,
  biddingInfo,
}) => {
  return (
    <ResponsiveComponent
      data={objects}
      soldObjects={soldObjects}
      houseTypes={houseTypes}
      municipalities={municipalities}
      associationTypes={associationTypes}
      biddingInfo={biddingInfo}
    />
  );
  return <>
    {objects.map((item, i) => {
      const houseType = createSlug(
        houseTypes.find((type) => type.HouseTypeID === item.HouseTypeId).Type
      );
      const municipality = createSlug(
        municipalities.find(
          (type) => type.MunicipalityID === item.MunicipalityID
        ).Name
      );
      const street = createSlug(item.Address);
      const url = `/${item.Slug}`;
      return (
        <Box key={i} p={1}>
          <Link href={url} as={url}>

            <Box sx={{ fontFamily: 'Avenir', fontSize: '20px' }}>
              {item.Headline + ' / ' + item.BaseID}
            </Box>

          </Link>
        </Box>
      );
    })}
  </>;
};

const mapStateToProps = (state) => {
  return {
    objects: state.main.objects,

    soldObjects: state.main.soldObjects,

    associationTypes: state.main.associationTypes,

    municipalities: state.main.municipalities,

    houseTypes: state.main.houseTypes,

    biddingInfo: state.main.biddingInfo,
  };
};

export default connect(mapStateToProps, null)(BuyList);
